<template>
  <gf-base>
    <el-row style="height: 100%" :gutter="20">
      <el-col :xl="6" :md="7" class="user-profile__aside">
        <el-card class="card user-profile__aside__content" :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: '30px' }">
          <el-row class="user-profile__aside__content__body">
            <el-col class="user-profile__aside__content__body__container">
              <div class="user-profile__aside__content__body__container__user">
                <div class="user-profile__aside__content__body__container__user__image">
                  <img v-if="user.avatar_url" :src="user.avatar_url" alt="">
                  <img v-else src="@/assets/avatar.jpg" alt="">
                </div>
                <div class="user-profile__aside__content__body__container__user__description">
                  <div class="user-profile__aside__content__body__container__user__description__name">
                    <span>{{ user.fname }} {{ user.lname }}</span>
                  </div>
                  <div class="user-profile__aside__content__body__container__user__description__position">
                    <span>{{ user.position.name }}</span>
                  </div>
                </div>
              </div>
              <div class="user-profile__aside__content__body__container__contact">
                <div class="user-profile__aside__content__body__container__contact__item">
                  <span class="user-profile__aside__content__body__container__contact__item__title">Email:</span>
                  <span class="user-profile__aside__content__body__container__contact__item__value">{{ user.email }}</span>
                </div>
                <div class="user-profile__aside__content__body__container__contact__item">
                  <span class="user-profile__aside__content__body__container__contact__item__title">Phone:</span>
                  <span class="user-profile__aside__content__body__container__contact__item__value">{{ user.phone_number }}</span>
                </div>
              </div>
              <div class="user-profile__aside__content__body__container__navigation">
                <div class="user-profile__aside__content__body__container__navigation__item" :class="{ 'user-profile__aside__content__body__container__navigation__item--active': active === 1 }" @click="handleNavClick(1)">
                  <span class="user-profile__aside__content__body__container__navigation__item__icon"><i class="el-icon-user-solid"></i></span>
                  <span class="user-profile__aside__content__body__container__navigation__item__text">Personal Information</span>
                </div>
                <div class="user-profile__aside__content__body__container__navigation__item" :class="{ 'user-profile__aside__content__body__container__navigation__item--active': active === 2 }" @click="handleNavClick(2)">
                  <span class="user-profile__aside__content__body__container__navigation__item__icon"><i class="el-icon-news"></i></span>
                  <span class="user-profile__aside__content__body__container__navigation__item__text">Account Information</span>
                </div>
                <div class="user-profile__aside__content__body__container__navigation__item" :class="{ 'user-profile__aside__content__body__container__navigation__item--active': active === 3 }" @click="handleNavClick(3)">
                  <span class="user-profile__aside__content__body__container__navigation__item__icon"><i class="el-icon-unlock"></i></span>
                  <span class="user-profile__aside__content__body__container__navigation__item__text">Change Password</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xl="18" :md="17" class="user-profile__container">
        <router-view :active.sync="active" @update="handleUpdate"></router-view>
      </el-col>
    </el-row>
  </gf-base>
</template>

<style lang="scss" scoped>
.card {
  flex-direction: column;
  height: 100%;
}

.user-profile {
  &__aside {
    height: 100%;

    &__content {
      &__header {
        &__container {
          display: flex;
          justify-content: flex-end;
        }
      }

      &__body {
        &__container {
          &__user {
            display: flex;
            align-items: center;

            &__image {
              margin-right: 15px;

              img {
                border-radius: 5px;
                width: 70px;
                height: 70px;
              }
            }

            &__description {
              &__name {
                font-size: 16.25px;
                font-weight: 500;
                color: #3F4254;
              }

              &__position {
                font-size: 13px;
                color: #B5B5C3;
              }

              &__button {
                margin: 8px 0;
              }
            }
          }

          &__contact {
            padding: 29px 0;
            font-size: 13px;

            &__item {
              display: flex;
              margin-bottom: 6.5px;

              &__title {
                flex-grow: 1;
                font-weight: 500;
                color: #3F4254;
              }

              &__value {
                color: #B5B5C3;
              }
            }
          }

          &__navigation {
            &__item {
              border-radius: 5.46px;
              transition: all 0.15s ease;
              color: #3F4254;
              font-size: 13px;
              padding: 13px 19.5px;
              display: flex;
              align-items: center;
              cursor: pointer;
              margin-bottom: 6.5px;

              $this: &;

              &:hover {
                background-color: #F3F6F9;
                color: #3699FF;

                & > #{$this}__icon {
                  color: #3699FF;
                }
              }

              &--active {
                background-color: #F3F6F9;
                color: #3699FF;

                & > #{$this}__icon {
                  color: #3699FF;
                }
              }

              &__icon {
                font-size: 16px;
                flex: 0 0 26px;
                color: #B5B5C3;
                transition: all 0.15s ease;
              }

              &__text {
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
</style>

<script>
// services
import UserProfileService from '@/services/v1/UserProfile'

export default {
  data () {
    return {
      active: 1,
      user: {
        fname: null,
        mname: null,
        lname: null,
        phone_number: null,
        email: null,
        position: {
          name: null
        }
      }
    }
  },
  methods: {
    handleNavClick (nav) {
      if (nav === this.active) return

      if (nav === 1) {
        this.$router.push({ name: 'user-profile-personal' })
        return
      }

      if (nav === 2) {
        this.$router.push({ name: 'user-profile-account' })
        return
      }

      if (nav === 3) {
        this.$router.push({ name: 'user-profile-password' })
      }
    },
    handleUpdate () {
      this.getUserProfile()
    },

    // fetch
    async getUserProfile () {
      try {
        const upService = new UserProfileService()
        const result = await upService.get()
        this.user = result.data
      } catch (error) {
        this.$Error(error)
      }
    }
  },
  mounted () {
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Settings', route: '' },
      { title: 'User Profile' }
    ])

    this.getUserProfile()
  }
}
</script>
